<template>
    <div class="mobile_title_wrapper ">
        <a href="#" @click.prevent="backFunction" class="mobile_title_back">
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
                    <defs>
                        <clipPath id="clip-path">
                            <path id="Clip_2" data-name="Clip 2" d="M0,0H40V40H0Z" transform="translate(0 0)" fill="none"/>
                        </clipPath>
                    </defs>
                    <g id="Group_3" data-name="Group 3" transform="translate(0 0)" clip-path="url(#clip-path)">
                        <path id="Fill_1" data-name="Fill 1" d="M20,40A20,20,0,1,1,40,20,20.022,20.022,0,0,1,20,40ZM21.765,13.64h0L15.4,20l6.364,6.36H24.6L18.241,20,24.6,13.64Z" transform="translate(0 0)" fill="#ffffff"/>
                    </g>
                </svg>
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                <g id="Icon_B_SimpleArrow_Black_Copy" data-name="Icon/B/SimpleArrow/Black Copy" transform="translate(0 18) rotate(-90)">
                    <path id="Fill_1" data-name="Fill 1" d="M9,0,0,8.995V13L9,4.013l9,8.982v-4Z" fill="#fff"/>
                </g>
            </svg>
        </a>
        <p>{{title}}</p>
    </div>
</template>

<script>
    import $ from 'jquery';

    export default {
        name: "MobileTitle",
        props: {
            title: {
                type: String,
                required: true
            }
        },
        methods: {
            backFunction() {
                if(this.$route.name == 'page') {
                    let path = this.$route.path;
                    path = path.replace(/\/$/, '');
                    path = path.split('/');
                    this.$router.push({ path: '/'+path[path.length-2] });

                } else if(window.history.length > 2) {
                    window.history.back();
                }
            },
            scroll() {
                const banner = $('.banner');
                    const thisElement = $(this.$el);
                    let bannerFirstPosition =  50;
                    let bannerSecondPosition = 50;
                    if(banner.length) {
                        bannerFirstPosition = banner.offset().top + banner.outerHeight() - 210;
                        bannerSecondPosition = banner.offset().top + banner.outerHeight() - 160;
                    }

                    if(window.scrollY > bannerFirstPosition ) {
                        thisElement.addClass('first_mode');
                        banner.find('.banner_title_wrapper').hide();
                    } else  {
                        thisElement.removeClass('first_mode');
                        banner.find('.banner_title_wrapper').show();
                    }

                    if(window.scrollY > bannerSecondPosition ) {
                        thisElement.addClass('second_mode');
                    } else  {
                        thisElement.removeClass('second_mode');
                    }
                }
        },
        mounted() {
            window.addEventListener('scroll', this.scroll);
            this.scroll();
            this.$nextTick(() => {
                this.scroll();
            });
            if(this.$route.path == '/') {
                $(this.$el).find('.mobile_title_back').hide();
            }
        }
    }
</script>

<style scoped>

</style>
