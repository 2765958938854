<template>
    <div class="page_section">
        <MobileTitle :title="'Salary Calculator'"/>
        <Banner :content="banner"/>

        <div class="container">
            <div class="tabs-wrapper">
                <div class="navigation">
                    <a href="#" :class="{ 'active' : activeTab === index, 'done' : activeTab > index }"
                       @click.prevent="setTab(index)"
                       v-for="index in 4" :key="index">{{ index }}</a>
                </div>
                <div class="tabs">
                    <div class="tab" :class="{ 'active' : activeTab === 1 }">
                        <div class="inner" v-if="activeTab === 1">
                            <div class="content">
                                <div>
                                    <h4>Welchen Beruf möchten Sie ausüben?</h4>
                                    <p>Ich möchte bei Ihnen folgenden Beruf ausüben:</p>
                                    <div>
                                        <select v-model="model.profession" class="form-control">
                                            <option value="" disabled selected="selected">Beruf auswählen*</option>
                                            <option v-for="(value, key) in formElements.profession"
                                                    :key="'option-' + key" :value="key">{{ value }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab" :class="{ 'active' : activeTab === 2 }">
                        <div class="inner" v-if="activeTab === 2">
                            <div class="content">
                                <div>
                                    <h4>Wie viel Berufserfahrung bringen Sie mit?</h4>
                                    <p>Ich habe folgende Berufserfahrung:</p>
                                    <div>
                                        <select v-model="model.years" class="form-control">
                                            <option value="" disabled selected="selected">Berufserfahrung angeben*</option>
                                            <option v-for="(value, key) in formElements.years"
                                                    :key="'option-' + key" :value="key">{{ value }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab" :class="{ 'active' : activeTab === 3 }">
                        <div class="inner" v-if="activeTab === 3">
                            <div class="content">
                                <div>
                                    <h4>Zuschläge berechnen</h4>
                                    <p>Zusätzlich zu Ihrem Grundgehalt können Sie verschiedene Zuschläge erhalten.
                                        Möchten Sie diese Zuschläge selbst eingeben oder sollen wir die
                                        durchschnittlichen Zuschläge nutzen?</p>
                                    <div>
                                        <label>
                                            <input type="radio" v-model="model.surcharges" value="1" name="surcharges">
                                            Zuschläge selbst eingeben
                                        </label>
                                        <label>
                                            <input type="radio" v-model="model.surcharges" value="2" checked="checked"
                                                   name="surcharges">
                                            Durchschnittliche Zuschläge nutzen
                                        </label>
                                    </div>
                                    <div class="surcharges-options" v-if="model.surcharges == 1">
                                        <div>
                                            <p>Stunden nachts (21:00 - 6:00 Uhr)</p>
                                            <input type="number" v-model="model.surchargesOptions.surcharges_night"
                                                   placeholder="Stunden eingeben">
                                        </div>
                                        <div>
                                            <p>Stunden an Sonntagen</p>
                                            <input type="number" v-model="model.surchargesOptions.surcharges_sunday"
                                                   placeholder="Stunden eingeben">
                                        </div>
                                        <div>
                                            <p>Stunden an Feiertagen</p>
                                            <input type="number" v-model="model.surchargesOptions.surcharges_holidays"
                                                   placeholder="Stunden eingeben">
                                        </div>
                                        <div>
                                            <p>Anzahl Schichten</p>
                                            <input type="number"
                                                   v-model="model.surchargesOptions.surcharges_shifts_count"
                                                   placeholder="Schichten eingeben">
                                        </div>
                                        <div>
                                            <p>Anzahl Überstunden</p>
                                            <input type="number" v-model="model.surchargesOptions.surcharges_shifts_overtime"
                                                   placeholder="Stunden eingeben">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab" :class="{ 'active' : activeTab === 4 }">
                        <div class="inner" v-if="activeTab === 4">
                            <div class="content">
                                <div>
                                    <div>
                                        <h4>Mein Beruf</h4>
                                        <p>{{ formElements.profession[model.profession] }}</p>
                                        <h4>Meine Berufserfahrung</h4>
                                        <p>{{ formElements.years[model.years] }}</p>
                                    </div>
                                    <div class="mb25">
                                        <h4>Gehalt pro Monat</h4>
                                        <ul class="price-list">
                                            <li>Grundlohn (brutto)
                                                <span>{{ calculatedData.brutoBasic }} €</span>
                                            </li>
                                            <li>abgabenpflichtige Zuschläge
                                                <span>{{ calculatedData.prices.bruttoInitial }} €</span>
                                            </li>
                                            <li>Zwischensumme (brutto)
                                                <span>{{ calculatedData.prices.subtotal }} €</span>
                                            </li>
                                            <li>abgabenfreie Zuschläge
                                                <span>{{ calculatedData.prices.netoInitial }} €</span>
                                            </li>
                                            <li>Gesamtlohn (brutto)
                                                <span>{{ calculatedData.prices.total }} €</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="mb25">
                                        <h4>Gehalt pro Jahr</h4>
                                        <ul class="price-list">
                                            <li>Grundlohn (brutto)
                                                <span>{{ this.calculatedData.brutoBasic * 12 }} €</span>
                                            </li>
                                            <li>abgabenpflichtige Zuschläge
                                                <span>{{ calculatedData.prices.brutoYearly }} €</span>
                                            </li>
                                            <li>Zwischensumme (brutto)
                                                <span>{{ calculatedData.prices.subtotalYearly }} €</span>
                                            </li>
                                            <li>jährliche Zuwendung
                                                <span>{{ calculatedData.brutoBasic / 2 }} €</span>
                                            </li>
                                            <li>abgabenfreie Zuschläge
                                                <span>{{ calculatedData.prices.netoYearly }} €</span>
                                            </li>
                                            <li>Gesamtlohn (brutto)
                                                <span>{{ calculatedData.prices.totalYearly }} €</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="mb25">
                                        <h4>Mein Urlaubsanspruch</h4>
                                        <p>{{ this.calculatedData.vacationDays }} Tage pro Jahr</p>
                                    </div>
                                    <div class="mb25">
                                        <h4>Meine Altersvorsorge</h4>
                                        <ul>
                                            <li>17,00 €/Monat zum Zweck des Aufbaus einer Altersvorsorge</li>
                                            <li>20% Arbeitgeberzuschuss des umgewandelten Entgeltbetrags</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h4>Meine Vorteile bei Abellio</h4>
                                        <ul>
                                            <li>bis zu 60% Preisnachlässe über unser Mitarbeitervorteilsportal</li>
                                            <li>Dienstbekleidung</li>
                                            <li>geregelte Pausen</li>
                                            <li>Aus- und Fortbildung</li>
                                            <li>faire Dienstpläne</li>
                                            <li>Schichtsymmetrie</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex actions-btn w-100 justify-content-between">
                        <a href="#" @click.prevent="prevTab">
                            zurücksetzen
                        </a>
                        <a href="#" class="text-capitalize" v-if="activeTab < 4" :disabled="model[Object.keys(model)[activeTab - 1]] === null" @click.prevent="nextTab">
                            {{ __t('next') }}
                            <i class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                    <g id="Icon_Small_SimpleArrow_Red" data-name="Icon/Small/SimpleArrow/Red"
                                       transform="translate(30) rotate(90)">
                                        <path d="M7.873,0,0,7.879v2.133L7.873,2.141l7.873,7.866V7.879Z"
                                              transform="translate(7 10)"/>
                                    </g>
                                </svg>
                            </i>
                        </a>
                        <a href="#" v-else></a>
                    </div>
                </div>
            </div>
        </div>

        <Footer/>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import MobileTitle from "../_common/MobileTitle";
    import Footer from "../_common/Footer";
    import Banner from "../_content_elements/Banner";

    export default {
        name: "RateCalculator",
        components: {
            Footer,
            MobileTitle,
            Banner
        },
        mounted() {

        },
        data() {
            return {
                banner: {
                    title: 'Salary Calculator',
                    image: '/abellio_banner.jpg',
                    smallBanner: true
                },
                model: {
                    profession: null,
                    years: null,
                    surcharges: null,
                    surchargesOptions: {
                        surcharges_night: null,
                        surcharges_sunday: null,
                        surcharges_holidays: null,
                        surcharges_shifts_count: null,
                        surcharges_shifts_overtime: null
                    }
                },
                activeTab: 1,
                formElements: {
                    profession: {
                        1: 'Triebfahrzeugführer (m/w/d)',
                        2: 'Kundenbetreuer im Zug (m/w/d)',
                        3: 'überörtlicher Disponent (m/w/d)',
                        4: 'Praxistrainer/Ausbilder (m/w/d)'
                    },
                    years: {
                        '4': 'bis 5 Jahre',
                        '9': 'Ab 5 bis < 10 Jahre',
                        '14': 'Ab 10 bis < 15 Jahre',
                        '19': 'Ab 15 bis < 20 Jahre',
                        '24': 'Ab 20 bis < 25 Jahre',
                        '25': 'Ab 25 bis < 30 Jahre',
                        '30': 'mehr als 30 Jahre'
                    },
                    calculations: {
                        1: {
                            4: {
                                'bruto': 3079,
                                'vacations': 27
                            },
                            9: {
                                'bruto': 3171,
                                'vacations': 28
                            },
                            14: {
                                'bruto': 3262,
                                'vacations': 29
                            },
                            19: {
                                'bruto': 3354,
                                'vacations': 29
                            },
                            24: {
                                'bruto': 3446,
                                'vacations': 29
                            },
                            25: {
                                'bruto': 3537,
                                'vacations': 29
                            },
                            30: {
                                'bruto': 3603,
                                'vacations': 29
                            }
                        },
                        2: {
                            4: {
                                'bruto': 2527,
                                'vacations': 27
                            },
                            9: {
                                'bruto': 2581,
                                'vacations': 28
                            },
                            14: {
                                'bruto': 2635,
                                'vacations': 29
                            },
                            19: {
                                'bruto': 2689,
                                'vacations': 29
                            },
                            24: {
                                'bruto': 2743,
                                'vacations': 29
                            },
                            25: {
                                'bruto': 2796,
                                'vacations': 29
                            },
                            30: {
                                'bruto': 2851,
                                'vacations': 29
                            }
                        },
                        3: {
                            4: {
                                'bruto': 3592,
                                'vacations': 27
                            },
                            9: {
                                'bruto': 3716,
                                'vacations': 28
                            },
                            14: {
                                'bruto': 3841,
                                'vacations': 29
                            },
                            19: {
                                'bruto': 3968,
                                'vacations': 29
                            },
                            24: {
                                'bruto': 4093,
                                'vacations': 29
                            },
                            25: {
                                'bruto': 4218,
                                'vacations': 29
                            },
                            30: {
                                'bruto': 4262,
                                'vacations': 29
                            }
                        },
                        4: {
                            4: {
                                'bruto': 3299,
                                'vacations': 27
                            },
                            9: {
                                'bruto': 3399,
                                'vacations': 28
                            },
                            14: {
                                'bruto': 3499,
                                'vacations': 29
                            },
                            19: {
                                'bruto': 3599,
                                'vacations': 29
                            },
                            24: {
                                'bruto': 3701,
                                'vacations': 29
                            },
                            25: {
                                'bruto': 3801,
                                'vacations': 29
                            },
                            30: {
                                'bruto': 3844,
                                'vacations': 29
                            }
                        },
                    }
                },
                calculatedData: {
                    netoBasicYearly: 0,
                    brutoBasic: 0,
                    prices: {
                        netoInitial: 160.76,
                        bruttoInitial: 136.33,
                        netoYearly: 0,
                        brutoYearly: 0,
                        subtotal: 0,
                        total: 0,
                        subtotalYearly: 0,
                        totalYearly: 0
                    },
                    vacationDays: 0
                }
            }
        },
        computed: {
            ...mapState([
                    'page',
                    'settings'
                ]),
        }
        ,
        methods: {
            prevTab() {
                if (this.activeTab !== 1)
                    this.activeTab--;
            }
            ,
            nextTab() {
                if(this.model[Object.keys(this.model)[this.activeTab - 1]] === null)
                    return;

                if (this.activeTab !== 4)
                    this.activeTab++;
                if (this.activeTab === 4)
                    this.formSubmit();
            },
            setTab(id) {
                if (this.model[Object.keys(this.model)[id - 1]] === null || (id === 4 && this.model[Object.keys(this.model)[3]]))
                    return;
                this.activeTab = id;
            },
            formSubmit() {
                this.calculatedData.brutoBasic = this.formElements.calculations[this.model.profession][this.model.years].bruto;
                this.calculatedData.vacationDays = this.formElements.calculations[this.model.profession][this.model.years].vacations;

                if (this.model.surcharges == 1)
                    this.calculateBasics();

                this.calculatedData.netoBasicYearly = this.calculatedData.brutoBasic / 2;
                this.calculatedData.brutoBasicYearly = this.calculatedData.brutoBasic * 12;

                this.calculatedData.prices.netoYearly = this.calculatedData.prices.netoInitial * 10.5;
                this.calculatedData.prices.brutoYearly = this.calculatedData.prices.bruttoInitial * 10.5;

                this.calculatedData.prices.subtotal = this.calculatedData.brutoBasic + this.calculatedData.prices.bruttoInitial;
                this.calculatedData.prices.total = this.calculatedData.prices.subtotal + this.calculatedData.prices.netoInitial;
                this.calculatedData.prices.subtotalYearly = this.calculatedData.prices.subtotal * 12;
                this.calculatedData.prices.totalYearly = this.calculatedData.prices.total * 12 + this.calculatedData.netoBasicYearly;

                this.priceValueCorrection();
            },
            calculateBasics() {
                this.calculatedData.prices.bruttoInitial = 0;
                this.calculatedData.prices.netoInitial = 0;

                if (this.model.surchargesOptions.surcharges_sunday) {
                    this.calculatedData.prices.netoInitial = this.calculatedData.prices.netoInitial + this.model.surchargesOptions.surcharges_sunday * 5.01;
                }
                if (this.model.surchargesOptions.surcharges_holidays) {
                    this.calculatedData.prices.netoInitial = this.calculatedData.prices.netoInitial + this.model.surchargesOptions.surcharges_holidays * 5.68;
                }
                if (this.model.surchargesOptions.surcharges_night) {
                    this.calculatedData.prices.netoInitial = this.calculatedData.prices.netoInitial + this.model.surchargesOptions.surcharges_night * 3.35;
                }
                if (this.model.surchargesOptions.surcharges_shifts_count) {
                    this.calculatedData.prices.bruttoInitial = this.calculatedData.prices.bruttoInitial + this.model.surchargesOptions.surcharges_shifts_count * 6.65;
                }
                if (this.model.profession != 2) {
                    if (this.model.surchargesOptions.surcharges_shifts_overtime) {
                        this.calculatedData.prices.bruttoInitial = this.calculatedData.prices.bruttoInitial + this.model.surchargesOptions.surcharges_shifts_overtime * 3.57;
                    }
                } else {
                    if (this.model.surchargesOptions.surcharges_shifts_overtime) {
                        this.calculatedData.prices.bruttoInitial = this.calculatedData.prices.bruttoInitial + this.model.surchargesOptions.surcharges_shifts_overtime * 3.10;
                    }
                }
            },
            priceValueCorrection () {
                let obj = this.calculatedData.prices;
                let _this = this;
                const currencyOptions = new Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                }).resolvedOptions();

                Object.keys(obj).forEach(function(key){
                    _this.calculatedData.prices[key] = parseFloat(obj[key]).toFixed(2).toLocaleString('de-DE', {
                        ...currencyOptions,
                        style: 'decimal',
                    });
                })
            }
        }
    }
</script>
