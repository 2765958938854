<template>
   <div class="banner content_element" :style="{backgroundImage: 'url('+ cmsAsset(encodeURI(content.image)) +')'}"
        :class="{small_banner: content.smallBanner, blogBanner: $route.name === 'blogArticle'}">
      <div class="container">
         <div class="banner_title_wrapper">
            <div class="container">
               <nl2br tag="h1" :text="content.title" v-if="content.title"/>
               <nl2br tag="h4" :text="content.subTitle" v-if="content.subTitle"/>
               <a v-if="content.buttonUrl && content.buttonText" :href="content.buttonUrl" class="btn mt-4" v-text="content.buttonText"></a>
            </div>
         </div>
         <div class="application_button_wrapper" v-if="parseInt(content.application_button) === 1">
            <div class="container">
               <a href="#" class="application_button">
                  <span>jetzt<br/>bewerben!</span>
               </a>
            </div>
         </div>
      </div>
      <div class="vertical_text" v-if="content.copyright">
         <span>{{ content.copyright }}</span>
      </div>
   </div>
</template>

<script>
   export default {
      name: "Banner",
      data () {
         return {
            button: {
               title: 'Zur Fahrplanauskunft',
               link: '#'
            }
         }
      },
      props: {
         content: {
            type: Object,
            required: true
         }
      }
   }
</script>
